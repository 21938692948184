/* eslint-disable no-unused-vars */

/* eslint-disable react/jsx-curly-newline */
import { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useDispatch, useSelector } from 'react-redux';

import { useMoengageEventTracker } from '../../hooks/useMoengage';
import useSourceScreen from '../../hooks/useSourceScreen';

import { getFooterData } from '../../redux/slices/footerSlice';

import FacebookIcon from '../../assets/images/FacebookIcon.svg';
import InstagramIcon from '../../assets/images/InstagramIcon.svg';
import LinkedInIcon from '../../assets/images/LinkedInIcon.svg';
import TelegramIcon from '../../assets/images/TelegramIcon.svg';
import TumblrIcon from '../../assets/images/TumblrIcon.svg';
import TwitterIcon from '../../assets/images/TwitterIcon.svg';
import MwebTwitterIcon from '../../assets/images/MwebTwitterIcon.svg';
import YoutubeIcon from '../../assets/images/YoutubeIcon.svg';

// eslint-disable-next-line import/no-unresolved
import { FRONTEND_DOMAIN } from '../../constants/api';

import styles from '../../styles/Footer.module.scss';
import AppStoreBtn from '../HomePage/HeroSection/AppStoreBtn';
import GooglePlayBtnNew from '../HomePage/HeroSection/GooglePlayBtnNew';
import MwebTumblrIcon from '../../assets/images/MwebTumblrIcon.svg';
import MwebInstagramIcon from '../../assets/images/MwebInstagramIcon.svg';
import MwebYoutubeIcon from '../../assets/images/MwebYoutubeIcon.svg';
import MwebFacebookIcon from '../../assets/images/MwebFacebookIcon.svg';
import MwebTelegramIcon from '../../assets/images/MwebTelegramIcon.svg';
import MwebLinkedInIcon from '../../assets/images/MwebLinkedInIcon.svg';

function Footer({ isShowFlag }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const screen = useSourceScreen();
  const trackMoengageEvent = useMoengageEventTracker();
  const [footerInfo, setFooterInfo] = useState({});
  const [allCourses, setAllCourses] = useState([]);
  const [allOtherCourses, setAllOtherCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [otherCourses, setOtherCourses] = useState([]);
  const [callUsData, setCallUsData] = useState([]);

  const { loading, footerData } = useSelector((state) => state?.footer);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        !(footerData && footerData !== '') &&
        (isShowFlag !== true || window?.location?.pathname === '/my-mock-test')
      ) {
        dispatch(getFooterData());
      }
    }
  }, [isShowFlag]);

  useEffect(() => {
    if (!loading) {
      setFooterInfo(footerData?.constantWeb);
      setAllCourses(footerData?.courses);
      setAllOtherCourses(footerData?.otherCourses);
    }
  }, [loading, footerData, footerInfo]);

  useEffect(() => {
    if (allCourses) {
      setCourses(allCourses);
    }
    if (allOtherCourses) {
      setOtherCourses(allOtherCourses);
    }
    if (footerInfo) {
      setCallUsData(footerInfo);
    }
  }, [footerInfo, allCourses, allOtherCourses]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleFooterMoe = () => {
    sessionStorage.removeItem('scrollToTryNew');
    scrollToTop();
    try {
      trackMoengageEvent('logo_clicked', {
        source_screen: screen,
        position: 'footer',
      });
    } catch (error) {
      // Handle Error
    }
  };

  const footerOnClick = (type, value) => {
    sessionStorage.removeItem('scrollToTryNew');
    try {
      trackMoengageEvent('footer_clicked', {
        Type: type,
        value,
      });
    } catch (error) {
      // Handle Error
    }
  };

  const whatAppSupportClick = () => {
    try {
      trackMoengageEvent('whatsapp_support_clicked', {
        screen_source: screen,
      });
    } catch (error) {
      // Handle Error
    }
    window.open(`https://wa.me/91${callUsData?.WHATS_UP}`);
  };

  const followUsClicked = () => {
    try {
      trackMoengageEvent('follow_us_clicked', {
        screen_source: screen,
      });
    } catch (error) {
      // Handle Error
    }
  };

  return (
    <div className={styles.footer_wrapper}>
      <div className={styles.container}>
        <div className={styles.row1}>
          <div className={styles.column1}>
            <div className={styles.followWrapper}>
              <div className={styles.followText}>Follow us</div>
              <div className={styles.socialLinks} onClick={() => followUsClicked()}>
                <a
                  href="https://www.linkedin.com/company/studyiq"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="StudyIQ LinkedIn"
                  data-testid="footer-link-linkedin"
                >
                  <LinkedInIcon />
                </a>
                <a
                  href="https://twitter.com/studyiq"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="StudyIQ Twitter"
                  data-testid="footer-link-twitter"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://t.me/Studyiqeducation"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="StudyIQ Telegram"
                  data-testid="footer-link-telegram"
                >
                  <TelegramIcon />
                </a>
                <a
                  href="https://www.facebook.com/studyiq/"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="StudyIQ Facebook"
                  data-testid="footer-link-facebook"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCrC8mOqJQpoB7NuIMKIS6rQ"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="StudyIQ Youtube"
                  data-testid="footer-link-youtube"
                >
                  <YoutubeIcon />
                </a>
                <a
                  href="https://www.tumblr.com/studyiqeducation"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="StudyIQ Tumblr"
                  data-testid="footer-link-tumblr"
                >
                  <TumblrIcon />
                </a>
                <a
                  href="https://www.instagram.com/studyiq.education/"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="StudyIQ Instagram"
                  data-testid="footer-link-instagram"
                >
                  <InstagramIcon />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.supportWrapper}>
              <div className={styles.instantText}>Support</div>
              <Link legacyBehavior href="/feedback" data-testid="footer-link-feedback">
                <div className={styles.feedbackPoint}>Raise a Query</div>
              </Link>
              <div className={styles.separatorText}>or</div>
              <div className={styles.whatsappSupportWrapper} onClick={() => whatAppSupportClick()}>
                <div className={styles.whatsappLinkWrapper}>
                  <Image src="/WhatsappIcon.png" width={22} height={22} alt="whatsapp" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className={styles.new1} />
        <div className={styles.row2}>
          <div className={styles.column2}>
            <div className={styles.footerInformation}>
              <Image src="/studyiqFooterIcon.png" width={145} height={145} alt="studyiq" onClick={handleFooterMoe} />
              <div className={styles.footerInfoText}>
                Call us {callUsData?.CALL_US}
                <br />({callUsData?.WORKING_HOURS}) <br />
                Email: {callUsData?.EMAIL} <br />
                {callUsData?.PATNER_US}
              </div>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.linkHead}>Company</div>
            <div className={styles.footerLinks}>
              <ul>
                <li>
                  <Link href="/" onClick={() => footerOnClick('company', 'Home')} data-testid="footer-link-home">
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    href="/about-us"
                    onClick={() => footerOnClick('company', 'About Us')}
                    data-testid="footer-link-about"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    href="/contact"
                    onClick={() => footerOnClick('company', 'Contact Us')}
                    data-testid="footer-link-contact"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    href="/articles"
                    onClick={() => footerOnClick('company', 'Blog')}
                    data-testid="footer-link-blog"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <a
                    href={`${FRONTEND_DOMAIN}/career`}
                    rel="noreferrer"
                    onClick={() => footerOnClick('company', 'Career with Us')}
                    data-testid="footer-link-career"
                  >
                    Career with Us
                  </a>
                </li>
                <li>
                  <a
                    href={`${FRONTEND_DOMAIN}/our-initiatives`}
                    rel="noreferrer"
                    onClick={() => footerOnClick('company', 'Our Initiative')}
                    data-testid="footer-link-initiatives"
                  >
                    Our Initiative
                  </a>
                </li>
                <li>
                  <a
                    href={
                      'https://www.studyiq.com/lp/' +
                      'teacher-excellence-program.html'
                    }
                    onClick={() => trackMoengageEvent('fep_clicked_footer')}
                    rel="noreferrer"
                    target="_blank"
                    data-testid="footer-link-teacher-program"
                  >
                    Faculty Excellence Program
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.linkHead}>Courses</div>
            <div className={styles.footerLinks}>
              <ul>
                {courses &&
                  courses.map((course) => (
                    <li key={course.id}>
                      <a
                        href={`${FRONTEND_DOMAIN}/view-all/${course.section_slug}`}
                        rel="noreferrer"
                        onClick={() => footerOnClick('courses', `${course.section_name}`)}
                      >
                        {course.section_name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className={`${styles.column2} ${styles.otherCourses}`}>
            <div className={styles.linkHead}>Other courses</div>
            <div className={styles.footerLinks}>
              <ul>
                {otherCourses &&
                  otherCourses.map((otherCourse) => (
                    <li key={otherCourse.id}>
                      <a
                        href={`${FRONTEND_DOMAIN}/view-all/${otherCourse.section_slug}`}
                        rel="noreferrer"
                        onClick={() => footerOnClick('other courses', `${otherCourse.section_name}`)}
                      >
                        {otherCourse.section_name.length > 20
                          ? `${otherCourse.section_name?.slice(0, 20)}..`
                          : otherCourse.section_name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.linkHead}>Policies</div>
            <div className={styles.footerLinks}>
              <ul>
                <li>
                  <Link
                    href="/terms-and-conditions"
                    onClick={() => footerOnClick('policies', 'Terms & Conditions')}
                    data-testid="footer-link-terms"
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    href="/return-policy"
                    onClick={() => footerOnClick('policies', 'Return & Refund Policy')}
                    data-testid="footer-link-return"
                  >
                    Return & Refund Policy
                  </Link>
                </li>
                <li>
                  <Link
                    href="/privacy-policy"
                    onClick={() => footerOnClick('policies', 'Privacy Policy')}
                    data-testid="footer-link-privacy"
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.GoogleBtn}>
              <GooglePlayBtnNew position="footer" />
              <AppStoreBtn position="footer" />
            </div>
          </div>
          <div className={`${styles.column2} ${styles.linksBlock}`}>
            <div className={styles.supportWrapper}>
              <div className={styles.supportText}>Support</div>
              <Link legacyBehavior href="/feedback" data-testid="footer-link-feedback">
                <div className={styles.queryBtn}>Raise a query</div>
              </Link>
              <div className={styles.separatorText}>or</div>
              <div className={styles.whatsappWrapper} onClick={whatAppSupportClick}>
                <Image src="/WhatsappIcon.png" width={20} height={20} alt="whatsapp" />
              </div>
            </div>
            <div className={styles.playStoreFooterBtn}>
              <GooglePlayBtnNew position="footer" isFooter="true" />
              <AppStoreBtn position="footer" isFooter="true" />
            </div>
            <div className={styles.miniSocialLinks}>
              <a
                href="https://www.linkedin.com/company/studyiq"
                rel="noreferrer"
                aria-label="StudyIQ LinkedIn"
                data-testid="footer-link-linkedin"
              >
                <MwebLinkedInIcon />
              </a>
              <a
                href="https://twitter.com/studyiq"
                rel="noreferrer"
                aria-label="StudyIQ Twitter"
                data-testid="footer-link-twitter"
              >
                <MwebTwitterIcon />
              </a>
              <a
                href="https://t.me/Studyiqeducation"
                rel="noreferrer"
                aria-label="StudyIQ Telegram"
                data-testid="footer-link-telegram"
              >
                <MwebTelegramIcon />
              </a>
              <a
                href="https://www.facebook.com/studyiq/"
                rel="noreferrer"
                aria-label="StudyIQ Facebook"
                data-testid="footer-link-facebook"
              >
                <MwebFacebookIcon />
              </a>
              <a
                href="https://www.youtube.com/channel/UCrC8mOqJQpoB7NuIMKIS6rQ"
                rel="noreferrer"
                aria-label="StudyIQ Youtube"
                data-testid="footer-link-youtube"
              >
                <MwebYoutubeIcon />
              </a>
              <a
                href="https://www.tumblr.com/studyiqeducation"
                rel="noreferrer"
                aria-label="StudyIQ Tumblr"
                data-testid="footer-link-tumblr"
              >
                <MwebTumblrIcon />
              </a>
              <a
                href="https://www.instagram.com/studyiq.education/"
                rel="noreferrer"
                aria-label="StudyIQ Instagram"
                data-testid="footer-link-instagram"
              >
                <MwebInstagramIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
